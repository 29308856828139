<template>
  <b-card>
    <b-form @submit.prevent="editEstablishmentPrompt()">
      <div class="d-flex">
        <feather-icon
          icon="ShoppingBagIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Información del establecimiento
        </h4>
      </div>
      <b-row class="mt-1">
        <b-col cols="12">
          <div class="d-flex mb-1">
            <base-cropper
              v-if="establishment"
              :model="establishment"
              @cropped-image="establishment.logo = $event"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            label="Nombre"
            label-for="name"
          >
            <b-form-input
              v-model="establishment.name"
              class="form-control"
              placeholder=""
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            label="Tipo de establecimiento"
            label-for="establishment_type"
          >
            <b-form-select
              v-model="establishment.establishment_type"
              :options="establishment_types"
            >
              <b-form-select-option
                v-if="userData.role_name === 'admin'"
                value="admin"
              >
                Admin
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            label="¿Provees productos/servicios a negocios?"
            label-for="is_supplier"
          >
            <b-form-select
              v-model="establishment.is_supplier"
              :options="is_supplier_options"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            label="Límite por usuario en ventas a crédito"
            label-for="loan_limit"
          >
            <b-input-group
              prepend="$"
            >
              <b-form-input
                v-model="establishment.loan_balance_limit"
                class="form-control"
                type="number"
                placeholder=""
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            label="Compartir saldo con sucursales para recargas y pago de servicios"
            label-for="balance-shared"
          >
            <b-form-checkbox
              id="balance-shared"
              v-model="establishment.is_balance_shared"
              switch
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Header: Personal Info -->
      <div class="d-flex mt-2">
        <feather-icon
          icon="UserIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Información de contacto
        </h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-row class="mt-1">

        <!-- Field: Birth Date -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Nombre"
            label-for="contact-name"
          >
            <b-form-input
              v-model="establishment.contact_name"
              class="form-control"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Mobile -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Teléfono"
            label-for="mobile"
          >
            <b-form-input
              id="mobile"
              v-model="establishment.contact_phone"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Website -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Email"
            label-for="website"
          >
            <b-form-input
              id="website"
              v-model="establishment.contact_email"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Header: Personal Info -->
      <div class="d-flex mt-2">
        <feather-icon
          icon="MapPinIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Dirección
        </h4>
      </div>

      <!-- Form: Establishment address -->
      <div v-show="keepOldAddress">
        <p class="d-flex flex-column">
          <span class="text-muted">
            ¿La dirección de tu tienda es la siguiente? Si no, buscala abajo
          </span>
          {{ establishment.address_attributes.street }} {{ establishment.address_attributes.ext_number }},
          CP {{ establishment.address_attributes.postal_code }}, {{ establishment.address_attributes.city }},
          {{ establishment.address_attributes.state }}, {{ establishment.address_attributes.country }}
        </p>
        <b-button class="mb-3" @click="changeAddress()">
          Modificar dirección
        </b-button>
      </div>
      <div v-show="!keepOldAddress">
        <address-form-model
          info-type
          @getAddress="setAddressForEdit($event)"
        />
      </div>
      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            type="submit"
          >
            Guardar
          </b-button>
        </b-col>
        <b-col>
          <b-button
            variant="outline-danger"
            class="mb-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :to="{ name: 'establishment-view', params: { id: $route.params.id } }"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BCard,
} from 'bootstrap-vue'
import BaseCropper from '@/@core/components/BaseCropper.vue'
import { mapActions } from 'vuex'
import AddressFormModel from '@/@core/components/AddressFormModel.vue'

export default {
  components: {
    AddressFormModel, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BCard, BaseCropper,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      establishment_types: [
        { value: 'groceries', text: 'Tiendita' },
        { value: 'school', text: 'Escuela' },
        { value: 'restaurant', text: 'Restaurante' },
        { value: 'flower_store', text: 'Librería' },
        { value: 'hardware_store', text: 'Ferretería' },
        { value: 'tortilleria', text: 'Tortillería' },
        { value: 'coffee_shop', text: 'Cafetería' },
        { value: 'clothing_store', text: 'Tienda de ropa' },
        { value: 'water_purifier', text: 'Purificadora de agua' },
        { value: 'corporate_education', text: 'Educación corporativa' },
        { value: 'other', text: 'Otra' },
      ],
      is_supplier_options: [
        { value: 'supplier', text: 'Si' },
        { value: 'not_supplier', text: 'No' },
      ],
      establishment: {
        address_attributes: {},
        logo: `${process.env.VUE_APP_API_URL}/default.jpg`,
      },
      keepOldAddress: true,
    }
  },
  mounted() {
    this.fetchEstablishment(this.$route.params.id)
      .then(response => {
        this.establishment = response
        this.addressId = `${this.establishment.address_attributes.id}`
      })
  },
  methods: {
    ...mapActions('establishments', ['fetchEstablishment', 'editEstablishment']),
    setAddressForEdit(x) {
      this.establishment.address_attributes = x
    },
    changeAddress() {
      this.keepOldAddress = !this.keepOldAddress
    },
    editEstablishmentPrompt() {
      this.$swal({
        title: '¿Estás seguro?',
        text: 'Estás apunto de editar la información de el establecimiento',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, ¡Quiero editar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.establishment.address_attributes.id = `${this.addressId}`
          this.editEstablishment({ id: this.$route.params.id, establishment: this.establishment })
            .then(() => {
              this.$swal({
                icon: 'success',
                title: '¡Establecimiento editado!',
                text: '😎',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
          this.$router.push({ name: 'establishment-view', params: { id: this.$route.params.id } })
        }
      })
    },
  },
}
</script>

<style lang="scss">

</style>
